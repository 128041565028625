var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-widget',{attrs:{"title":"Red de Usuarios","simpleToolbar":"","icon":"mdi-sitemap","loading":_vm.loading}},[_c('div',{attrs:{"slot":"widget-header-action"},slot:"widget-header-action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.loading},on:{"click":_vm.loadData}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])])],1),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'px-2' : '',attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('form-base',{ref:"filters",attrs:{"dense":true,"classContainer":"px-0 pt-0","model":_vm.pagerequest,"items":_vm.filterItems},model:{value:(_vm.filterForm),callback:function ($$v) {_vm.filterForm=$$v},expression:"filterForm"}}),_c('v-data-table',{staticClass:"elevation-0 striped simple-list",attrs:{"headers":_vm.columns,"items":_vm.list,"items-per-page":_vm.pageSize,"server-items-length":_vm.totalCount,"options":_vm.options,"loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","show-expand":"","dense":"","header-props":{
                            sortByText: 'Ordenar por'
                          },"footer-props":{
                              itemsPerPageOptions: [5,10,20,50],
                              itemsPerPageText: _vm.L('Registros por Pagina'),
                              pageText: '{0}-{1} de {2}',
                              showFirstLastPage: true,
                            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"item.state_id",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getItem(item.state_id).extra,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getItem(item.state_id).name)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"#F8BB86"}},[_vm._v("info_outline")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Aun no hay registros!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
                            var headers = ref.headers;
                            var item = ref.item;
return [_c('td'),_c('td',{staticClass:"px-0",staticStyle:{"border":"2px solid green"},attrs:{"colspan":headers.length - 1}},[_c('v-data-table',{staticClass:"striped",attrs:{"headers":_vm.columns2,"items":item.sponsored,"loading":_vm.loading,"dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.state_id",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getItem(item.state_id).extra,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getItem(item.state_id).name)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"60px","color":"#F8BB86"}},[_vm._v("groups")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Aun no tiene amigos!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }